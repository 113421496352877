import { S3Client } from "@aws-sdk/client-s3"

const THE_KEYS = "AKIAW4FR4ZMJYOLY5ZBI01052olu4+RvgX9eYiOly28hS2QC6VYVPEoyDgpZTMNl"
const API_KEY = THE_KEYS.split ("0105") [0];
const SECRET_KEY = THE_KEYS.split ("0105")[1];

const config = {
  region: 'ap-southeast-1',
  credentials: {
    accessKeyId: API_KEY,
    secretAccessKey: SECRET_KEY
  }
}

const client = new S3Client(config);

export {
  client
}